<template>
  <base-page
    :title="$t('assignDistribute.title')"
    :formData="formData"
    :config="config"
    @functionMethod="functionMethod"
    @search="search"
  >
    <table-list
      ref="tableList"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.serviceResourceAssignFind"
      :columns="columns"
      :defaultParams="params"
      row-name="targetCompany.name"
      v-model="tableRow"
    >
      <!-- 授权时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>
      <!-- 授权企业 -->
      <template v-slot:targetCompany="{ row }">
        {{ row.targetCompany.name }}
      </template>
      <!-- 详情 -->
      <template v-slot:assignDetailSet="{ row }">
        <p v-for="item in row.assignDetailSet" :key="item.id">
          {{ $t('assignDistribute.assignName') }}：{{
            resourceTypeEnumObj[item.type]
          }}，{{ $t('assignDistribute.assignNumber') }}：
          {{ getAssignNumber(item) }}
        </p>
      </template>
      <!-- 状态 -->
      <template v-slot:status="{ row }">
        <span v-if="row.status === 'ASSIGNED'" style="color: #2fdd60">
          {{ assignStatusEnumObj[row.status] }}
        </span>
        <span v-else style="color: #ff8a2b">
          {{ assignStatusEnumObj[row.status] }}
        </span>
      </template>
      <!-- 操作 -->
      <template v-slot:option="{ row }">
        <el-button
          type="text"
          v-if="
            $page.showBtn('ASSIGN_TO_OTHER_MANAGE_ROLLBACK') &&
            row.status === 'ASSIGNED'
          "
          @click="rollback(row)"
        >
          {{ $t('functionEnum.ASSIGN_TO_OTHER_MANAGE_ROLLBACK') }}
        </el-button>
      </template>
    </table-list>
    <assign v-if="showAssign" @closePage="closePage" />
  </base-page>
</template>

<script>
import Assign from './assign'

export default {
  components: {
    Assign,
  },
  data() {
    return {
      // 搜索
      formData: {},
      config: [
        {
          tag: 'el-select',
          label: this.$t('assignDistribute.targetCompany'),
          prop: 'targetCompanyUid',
          span: 6,
          tagProps: {
            options: [],
          },
        },
        {
          tag: 'el-select',
          label: this.$t('assignDistribute.status'),
          prop: 'status',
          span: 6,
          tagProps: {
            options: this.$dict.assignStatusEnum(),
          },
        },
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 8,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ],
      // 表格页
      tableRow: null,
      params: {
        companyUid: this.$store.getters.company.uid,
      },
      assignStatusEnumObj: this.$util.listToObj(this.$dict.assignStatusEnum()),
      resourceTypeEnumObj: this.$util.listToObj(this.$dict.resourceTypeEnum(this.$util.getLocalStorage('apiprefix'))),
      columns: [
        // lang:创建时间
        {
          label: this.$t('assignDistribute.createTime'),
          prop: 'createTime',
        },
        // lang:授权企业
        {
          label: this.$t('assignDistribute.targetCompany'),
          prop: 'targetCompany',
        },
        // lang:详情
        {
          label: this.$t('assignDistribute.assignDetailSet'),
          prop: 'assignDetailSet',
          width: '380px',
        },
        // lang:状态
        {
          label: this.$t('assignDistribute.status'),
          prop: 'status',
        },
        // lang:操作
        {
          label: this.$t('base.option'),
          prop: 'option',
          width: '100px',
        },
      ],
      // 授权内容
      showAssign: false,
    }
  },
  mounted() {
    this.companyFind()
  },
  methods: {
    // 获取数量
    getAssignNumber(item) {
      let assignNumber = +item.assignNumber
      if (item.type === 'VIDEO_CALL_DURATION') {
        assignNumber = +parseFloat(assignNumber / 60).toFixed(2)
      } else if (
        item.type === 'CLOUD_RECORDING_STORAGE_SPACE' ||
        item.type === 'OSS_STORAGE_SPACE'
      ) {
        assignNumber = +parseFloat(assignNumber / 1024 / 1024 / 1024).toFixed(4)
      }
      return assignNumber
    },
    // 获取企业列表
    companyFind() {
      this.$ajax({
        url: this.$api.companyFind,
        data: {
          productType : this.$util.getLocalStorage('productType')
        }
      }).then((data) => {
        this.config[0].tagProps.options = data.map((item) => ({
          label: item.name,
          value: item.uid,
        }))
      })
    },
    // 关闭详情页回调
    closePage(method) {
      this.showAssign = false
      if (method && method !== 'cancel') {
        this.search()
      }
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      this.$refs.tableList.searchList(this.formData, isActiveQuery)
    },
    // 权限按钮点击回掉
    functionMethod(item) {
      const { method } = item
      if (method !== 'add' && !this.tableRow) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      switch (method) {
        case 'add':
          this.showAssign = true
          break
        default: // do something
      }
    },
    // 撤销
    rollback() {
      this.$element
        .showConfirm({
          content: this.$t('assignDistribute.rollbackConfirm'),
        })
        .then(() => {
          this.$ajax({
            url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.serviceResourceAssignRollback,
            data: {
              assignId: this.tableRow.id,
            },
          }).then(() => {
            // lang:撤销成功
            this.$element.showMsg(
              this.$t('assignDistribute.rollbackSuccess'),
              'success'
            )
            this.search()
          })
        })
    },
  },
}
</script>
